<template>
  <div v-click-outside="vcoConfig" class="tw-cursor-pointer tw-relative">
    <div @click="isActive = !isActive">
      <i-ellipsis class="tw-w-3 tw-fill-[#0DB2B2]" />
    </div>
    <div
      @click="onClosedDropdowns"
      :class="isActive ? 'tw-block' : 'tw-hidden'"
      class="tw-absolute tw-top-4 tw-right-0 tw-py-2 tw-shadow tw-rounded tw-bg-white tw-z-[1] tw-font-medium"
    >
      <slot name="options" />
    </div>
  </div>
</template>

<script>
import IEllipsis from '@/components/icons/iEllipsis.vue'

export default {
  components: {IEllipsis},
  data() {
    return {
      isActive: false,
      vcoConfig: {
        handler: this.onClosedDropdowns,
        events: ['dblclick', 'click'],
        isActive: true,
        detectIFrame: true,
        capture: false,
      },
    }
  },
  methods: {
    onClosedDropdowns() {
      this.isActive = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
